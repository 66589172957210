<template>
	<div>
		<div class="flex items-center mb-5 mt-5">
			<h1 class="w-4/12 text-xl h-6 font-extrabold sm:text-2xl">Медицинские организации</h1>
			<div class="flex justify-end w-full items-center">
				<!-- <Button v-if="userIsInRoles(['Support', 'MZ_Employee'])" class="mr-2" text="Добавить МО" icon="add" @click.native="openPopUp()" /> -->
				<Button text="Экспорт в Excel" icon="exel" @click.native="downloadExcell(params)" :disabled="loadToExcell" :load="loadToExcell" />
			</div>
		</div>
		<div class="flex flex-col relative">
			<div class="t-overflow rounded-t-lg overflow-x-auto h-auto bg-white filter shadow-default" :class="{ 'custom-w-table-144': !menu, 'custom-w-table-360': menu }">
				<table :style="medOrgs.length > 0 ? 'min-height: 300px' : ''">
					<thead>
						<tr>
							<th
								class="w-full px-6 py-4 border-b border-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
								v-for="(item, ind) in fields"
								:class="{ 'sticky-table__header min-w-min': ind === 0, 'row-w': ind !== 0 && ind !== fields.length - 1 }"
								:key="'J' + ind"
							>
								<span class="relative">
									<span class="font-semibold border-b border-gray-200 cursor-pointer" :class="{ 'text-blue-500 underline': filterBy === ind }" @click="switchFilterVisibility(ind)">
										{{ item.Name }}
									</span>

									<!-- Фильтр и сортировка -->
									<FilterComponent
										v-on:change="switchFilterVisibility(null)"
										:position="ind === 0 ? 'left' : 'right'"
										:filterOpen="filterOpen"
										:id-filter="ind"
										:param="params"
										@query="getData"
										:fields="fields"
										:defaultSortField="'RegionName'"
										:item="item"
									/>
								</span>
							</th>
						</tr>
					</thead>
					<tbody class="bg-white" v-for="(item, index) in medOrgs" :key="'G' + index">
						<tr :class="{ 'bg-gray-30': item.id === opened }">
							<td
								class="sticky-table__row px-6 py-4 text-sm whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500"
								:style="item.id === opened ? 'background: #F2F5FF' : ''"
							>
								<div class="flex items-center">
									<Icons v-if="userIsInRoles(['Support', 'MZ_Employee'])" class="mr-2 cursor-pointer" icon-name="pen" @click.native="editMO(item, index)" />
									<span @click="openRow(item)" :class="{ 'underline tex-blue-400 cursor-pointer': item.hasBranch }">
										{{ item.name }}
									</span>
								</div>
							</td>
							<td class="row-w-2 px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
								<span>{{ item.shortName ? item.shortName : '---' }}</span>
							</td>
							<td class="row-w px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
								<span>{{ item.regionId ? setName(item.regionId, 'regions') : '---' }}</span>
							</td>
							<td class="row-w px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
								<span>{{ item.address ? item.address : '---' }}</span>
							</td>
							<td class="row-w px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
								<span>{{ item.postAddress ? item.postAddress : '---' }}</span>
							</td>
							<td class="row-w px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
								<span>{{ item.ownerShipId ? setName(item.ownerShipId, 'ownership') : '---' }}</span>
							</td>
							<td class="row-w px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
								<span>{{ item.inn ? item.inn : '---' }}</span>
							</td>
							<td class="row-w px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
								<span>{{ item.webSiteAddress ? item.webSiteAddress : '---' }}</span>
							</td>
							<td class="row-w px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
								<span>{{ item.departmentBelongId ? setName(item.departmentBelongId, 'departments') : '---' }}</span>
							</td>
							<td class="row-w px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
								<span>{{ item.nomenclatureId ? setName(item.nomenclatureId, 'nomenclatures') : '---' }}</span>
							</td>
							<td class="row-w px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
								<span>{{ item.noteId ? setName(item.noteId, 'notes') : '---' }}</span>
							</td>
							<td class="row-w px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
								<span v-if="item.years">
									{{
										item.years
											.map((item) => {
												return item.year;
											})
											.join(', ')
									}}
								</span>
								<span v-else> - </span>
							</td>
							<td class="row-w px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
								<span>{{ item.ambulator ? 'Да' : 'Нет' }}</span>
							</td>
							<td class="row-w px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
								<span>{{ item.stacionar ? 'Да' : 'Нет' }}</span>
							</td>
							<td class="row-w px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
								<span>{{ item.healthResort ? 'Да' : 'Нет' }}</span>
							</td>
							<td class="row-w px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
								<span>{{ item.psychiatric ? 'Да' : 'Нет' }}</span>
							</td>
							<td class="row-w px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
								<span v-if="item.noteId === '150a6a2c-e499-435d-9c2c-8488e60f5555' || item.noteId === '789200c6-8ddd-4dcd-abe5-3e3ab5e9ad7f'">
									<span class="underline text-blue-500 cursor-pointer" @click="openBunnersPopUp(item)"> Получить баннер </span>
								</span>
								<span v-else> - </span>
							</td>
							<td class="px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
								<span v-if="item.noteId === '150a6a2c-e499-435d-9c2c-8488e60f5555' || item.noteId === '789200c6-8ddd-4dcd-abe5-3e3ab5e9ad7f'">
									<span class="underline text-blue-500 cursor-pointer" @click="downloadQr(item)"> Скачать </span>
								</span>
								<span v-else> - </span>
							</td>
						</tr>
						<tr v-if="item.id === opened" :class="{ 'bg-gray-20': item.id === opened }">
							<td :colspan="fields.length">
								<table class="w-full">
									<tbody>
										<tr v-for="(it, ind1) in item.branches" :key="'U' + ind1">
											<td class="sticky-table__row-2 px-6 py-4 text-sm whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
												<div class="flex items-center">
													<div v-if="it.isBranch" class="default-filial-clone">Филиал</div>
													<div v-if="it.isClone" class="default-filial-clone-2 ml-2">Вторичная МО</div>
												</div>
												<div class="flex items-center">
													<Icons
														v-if="userIsInRoles(['Support', 'MZ_Employee']) && !it.isClone"
														class="mr-2 cursor-pointer"
														icon-name="pen"
														@click.native="editMO(it, index)"
													/>
													<span>{{ it.name }}</span>
												</div>
											</td>
											<td class="row-w-2 px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
												<span>{{ it.shortName ? it.shortName : '---' }}</span>
											</td>
											<td class="row-w px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
												<span>{{ it.regionId ? setName(it.regionId, 'regions') : '---' }}</span>
											</td>
											<td class="row-w px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
												<span>{{ it.address ? it.address : '---' }}</span>
											</td>
											<td class="row-w px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
												<span>{{ it.postAddress ? it.postAddress : '---' }}</span>
											</td>
											<td class="row-w px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
												<span>{{ it.ownerShipId ? setName(it.ownerShipId, 'ownership') : '---' }}</span>
											</td>
											<td class="row-w px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
												<span>{{ it.inn ? it.inn : '---' }}</span>
											</td>
											<td class="row-w px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
												<span>{{ it.webSiteAddress ? it.webSiteAddress : '---' }}</span>
											</td>
											<td class="row-w px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
												<span>{{ it.departmentBelongId ? setName(it.departmentBelongId, 'departments') : '---' }}</span>
											</td>
											<td class="row-w px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
												<span>{{ it.nomenclatureId ? setName(it.nomenclatureId, 'nomenclatures') : '---' }}</span>
											</td>
											<td class="row-w px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
												<span>{{ it.noteId ? setName(it.noteId, 'notes') : '---' }}</span>
											</td>
											<td class="row-w px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
												<span v-if="it.years">
													{{
														it.years
															.map((it) => {
																return it.year;
															})
															.join(', ')
													}}
												</span>
												<span v-else> - </span>
											</td>
											<td class="row-w px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
												<span>{{ it.ambulator ? 'Да' : 'Нет' }}</span>
											</td>
											<td class="row-w px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
												<span>{{ it.stacionar ? 'Да' : 'Нет' }}</span>
											</td>
											<td class="row-w px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
												<span>{{ it.healthResort ? 'Да' : 'Нет' }}</span>
											</td>
											<td class="row-w px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
												<span>{{ it.psychiatric ? 'Да' : 'Нет' }}</span>
											</td>
											<td class="row-w px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
												<span v-if="it.noteId === '150a6a2c-e499-435d-9c2c-8488e60f5555' || it.noteId === '789200c6-8ddd-4dcd-abe5-3e3ab5e9ad7f' || !it.isClone">
													<span class="underline text-blue-500 cursor-pointer" @click="openBunnersPopUp(it)"> Получить баннер </span>
												</span>
												<span v-else> - </span>
											</td>
											<td class="px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
												<span v-if="it.noteId === '150a6a2c-e499-435d-9c2c-8488e60f5555' || it.noteId === '789200c6-8ddd-4dcd-abe5-3e3ab5e9ad7f' || !it.isClone">
													<span class="underline text-blue-500 cursor-pointer" @click="downloadQr(it)"> Скачать </span>
												</span>
												<span v-else> - </span>
											</td>
										</tr>
									</tbody>
								</table>
							</td>
						</tr>
					</tbody>
				</table>
				<center v-if="!loadingTable && medOrgs.length === 0" class="w-full py-28 whitespace-no-wrap text-xl leading-5 text-gray-500 text-center">Данных нет...</center>
			</div>
			<!-- Навигация -->
			<div class="h-auto rounded-b-lg bg-white filter shadow-default">
				<div class="hidden sm:flex flex-row pt-6 px-20 pb-12 text-gray-400 text-sm">
					<div class="flex flex-row ml-auto">
						<p>Строк на странице:</p>
						<ul class="flex flex-row">
							<li @click="updateRow(5)" class="flex ml-4 cursor-pointer" :class="{ 'text-blue-500': rowsVisible == 5 }">5</li>
							<li @click="updateRow(10)" class="flex ml-4 cursor-pointer" :class="{ 'text-blue-500': rowsVisible == 10 }">10</li>
							<li @click="updateRow(15)" class="flex ml-4 cursor-pointer" :class="{ 'text-blue-500': rowsVisible == 15 }">15</li>
						</ul>
					</div>
					<div class="flex flex-row ml-24">
						<ul class="flex flex-row">
							<li class="flex ml-4 cursor-pointer arrow" @click="page !== 0 ? navigation('params', page - 1) : ''" />
							<li class="flex">
								<span>Стр</span>
								<b class="ml-2">{{ page + 1 }}</b>
								<span class="ml-2">из</span>
								<b class="ml-2">{{ totalCount === 0 ? 1 : totalCount }}</b>
							</li>
							<li class="flex ml-2 cursor-pointer arrow" @click="page + 1 < totalCount ? navigation('params', page + 1) : ''" />
						</ul>
					</div>
				</div>
			</div>

			<div v-if="loadingTable" class="absolute w-full h-full preloader rounded-lg">
				<loadingComponent class="absolute top-1/2 left-1/2" />
			</div>
		</div>

		<PopUp :open="open" :title="popupTitle" size="900px" @close="closePopup()">
			<div class="flex w-full gap-5">
				<div class="w-6/12">
					<div class="input-box" :class="{ error: error.regionId }">
						<label>Регион*</label>
						<v-select :options="regions" :disabled="innClear" v-model="infoMO.regionId" label="name" :reduce="(option) => option.value" />
						<p class="error-text" v-if="error.regionId">{{ error.regionId }}</p>
					</div>
					<TextInput label="Краткое наименование МО*" :title="infoMO.shortName" :disabled="innClear" v-model="infoMO.shortName" :error="error.shortName" />
					<TextInput label="Почтовый адрес*" :title="infoMO.postAddress" :disabled="innClear" v-model="infoMO.postAddress" :error="error.postAddress" />
					<TextInput label="ИНН*" :title="infoMO.inn" @input="setInn(infoMO.inn)" type="number" v-model="infoMO.inn" :error="error.inn" />
					<div class="input-box" v-if="userIsInRoles(['Support', 'MZ_Employee'])">
						<label>Ведомственная принадлежность</label>
						<v-select :options="departments" :disabled="innClear" label="name" v-model="infoMO.departmentBelongId" :reduce="(option) => option.value" />
					</div>
					<div class="input-box" :class="{ error: error.years }" v-if="yearsAreAvailable(infoMO.noteId)">
						<label class="select-label"> Включено в перечень* </label>
						<multiselect
							tag-placeholder=""
							:disabled="innClear"
							@change="setYears(infoMO.years)"
							placeholder=""
							:hide-selected="true"
							:searchable="false"
							:multiple="true"
							:options="options"
							label="year"
							track-by="year"
							v-model="infoMO.years"
						>
						</multiselect>
						<p class="error-text" v-if="error.years">{{ error.years }}</p>
					</div>
					<div class="flex justify-between" v-if="infoMO.noteId === '150a6a2c-e499-435d-9c2c-8488e60f5555' || infoMO.noteId === '789200c6-8ddd-4dcd-abe5-3e3ab5e9ad7f'">
						<div class="radio-group">
							<p class="select-label">Амбулаторные*</p>
							<div class="flex">
								<label>
									<input type="radio" name="ambulator" :disabled="innClear" :value="true" v-model="infoMO.ambulator" />
									<span class="ml-2">Да</span>
								</label>
								<label class="ml-2">
									<input type="radio" name="ambulator" :disabled="innClear" :value="false" v-model="infoMO.ambulator" />
									<span class="ml-2">Нет</span>
								</label>
							</div>
						</div>
						<div class="radio-group">
							<p class="select-label">Стационарные*</p>
							<div class="flex">
								<label>
									<input type="radio" name="stacionar" :disabled="innClear" :value="true" v-model="infoMO.stacionar" />
									<span class="ml-2">Да</span>
								</label>
								<label class="ml-2">
									<input type="radio" name="stacionar" :disabled="innClear" :value="false" v-model="infoMO.stacionar" />
									<span class="ml-2">Нет</span>
								</label>
							</div>
						</div>
					</div>
					<div class="radio-group">
						<p class="select-label">Филиал*</p>
						<div class="flex">
							<label>
								<input type="radio" name="filial" @change="setFilial(true)" :value="true" v-model="infoMO.isBranch" />
								<span class="ml-2">Да</span>
							</label>
							<label class="ml-2">
								<input type="radio" name="filial" @change="setFilial(false)" :value="false" v-model="infoMO.isBranch" />
								<span class="ml-2">Нет</span>
							</label>
						</div>
					</div>
				</div>
				<div class="w-6/12">
					<TextInput label="Полное наименование МО*" :title="infoMO.name" :disabled="innClear" v-model="infoMO.name" :error="error.name" />
					<TextInput label="Фактический адрес*" :title="infoMO.address" :disabled="innClear" v-model="infoMO.address" :error="error.address" />
					<div class="input-box" :class="{ error: error.ownerShipId }">
						<label>Форма собственности*</label>
						<v-select :options="ownership" :disabled="innClear" v-model="infoMO.ownerShipId" label="name" taggable :reduce="(option) => option.value" />
						<p class="error-text" v-if="error.ownerShipId">{{ error.ownerShipId }}</p>
					</div>
					<TextInput label="Адрес официального сайта*" :disabled="innClear" v-model="infoMO.webSiteAddress" :error="error.webSiteAddress" />
					<div class="input-box" :class="{ error: error.nomenclatureId }">
						<label>Номенклатура*</label>
						<v-select
							class="search"
							:options="nomenclatures"
							:disabled="innClear"
							@input="notesChange(infoMO.nomenclatureId)"
							v-model="infoMO.nomenclatureId"
							label="name"
							taggable
							:reduce="(option) => option.value"
						/>
						<p class="error-text" v-if="error.nomenclatureId">{{ error.nomenclatureId }}</p>
					</div>
					<div class="input-box" :class="{ error: error.noteId }">
						<label>Примечание*</label>
						<v-select class="search" :options="notes" :disabled="innClear" v-model="infoMO.noteId" label="name" :reduce="(option) => option.value" />
						<p class="error-text" v-if="error.noteId">{{ error.noteId }}</p>
					</div>
					<div class="flex justify-between" v-if="infoMO.noteId === '150a6a2c-e499-435d-9c2c-8488e60f5555' || infoMO.noteId === '789200c6-8ddd-4dcd-abe5-3e3ab5e9ad7f'">
						<div class="radio-group">
							<p class="select-label">Психиатрические*</p>
							<div class="flex">
								<label>
									<input type="radio" name="psychiatric" :disabled="innClear" :value="true" v-model="infoMO.psychiatric" />
									<span class="ml-2">Да</span>
								</label>
								<label class="ml-2">
									<input type="radio" name="psychiatric" :disabled="innClear" :value="false" v-model="infoMO.psychiatric" />
									<span class="ml-2">Нет</span>
								</label>
							</div>
						</div>
						<div class="radio-group">
							<p class="select-label">Санаторно-курортные*</p>
							<div class="flex">
								<label>
									<input type="radio" name="healthResort" :disabled="innClear" :value="true" v-model="infoMO.healthResort" />
									<span class="ml-2">Да</span>
								</label>
								<label class="ml-2">
									<input type="radio" name="healthResort" :disabled="innClear" :value="false" v-model="infoMO.healthResort" />
									<span class="ml-2">Нет</span>
								</label>
							</div>
						</div>
						<!-- <RadioGroup label="Психиатрические*" :option="['Да', 'Нет']"/>
						<RadioGroup label="Санаторно-курортные*" :option="['Да', 'Нет']"/> -->
					</div>
					<div class="input-box" :class="{ error: error.parentId }" v-if="infoMO.isBranch">
						<label>Головная организация*</label>
						<v-select :options="moArr" label="name" v-model="infoMO.parentId" class="search" :reduce="(option) => option.value" />
					</div>
				</div>
			</div>
			<div class="flex mt-5">
				<Button size="medium" class="mr-3" color="white" text="Отмена" @click.native="closePopup()" />
				<Button size="medium" :disabled="innClear" text="Сохранить" @click.native="sendForm()" />
			</div>
		</PopUp>
		<PopUp :open="openBunners" size="95%" title="Получить баннер" @close="closeBunners()">
			<div class="flex justify-between">
				<Bunner :ids="bunnersId" level="mo" type="1"></Bunner>
				<Bunner :ids="bunnersId" level="mo" type="2"></Bunner>
				<Bunner :ids="bunnersId" level="mo" type="3"></Bunner>
			</div>
		</PopUp>
		<PopUp :open="openClonInn">
			<p>
				МО с таким ИНН уже существует. Создать еще одно МО с тем же ИНН можно в двух случаях: если оно является филиалом медицинского учреждения, то выберите ниже вариант «Филиал», если
				учреждение обслуживает пациентов из другого региона (отличного от региона, в котором находится учреждение), то выберите вариант «МО обслуживает другой регион»
			</p>
			<div class="flex mt-5">
				<Button size="medium" class="mr-3" text="Филиал" @click.native="filial(true)" />
				<Button size="medium" text="МО обслуживает другой регион" @click.native="filial(false)" />
			</div>
		</PopUp>
	</div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import Icons from '@/components/Icons.vue';
import PopUp from '@/components/PopUp.vue';
import FilterComponent from '@/components/Filter.vue';
import Button from '@/components/Button.vue';
import loadingComponent from '../../../../components/Loading.vue';
import Bunner from '@/components/Bunner.vue';
import TextInput from '@/components/TextInput.vue';
// Глобальные данные стора
import { getters, mutations, actions, methods, compareAsc } from '@/store/store';
// Локальные данные стора
import actionsLocal from '../store/actions';

export default {
	name: 'TableMain',
	components: {
		Icons,
		Bunner,
		Multiselect,
		Button,
		PopUp,
		TextInput,
		loadingComponent,
		FilterComponent
	},
	data() {
		return {
			loadingTable: false,
			open: false,
			openClonInn: false,
			openBunners: false,
			bunnersId: '',
			rowsVisible: 5,
			totalCount: 0,
			page: 0,
			regions: [],
			infoMO: {
				name: '',
				regionId: '',
				shortName: '',
				address: '',
				postAddress: '',
				ownerShipId: '',
				inn: '',
				webSiteAddress: '',
				departmentBelongId: null,
				nomenclatureId: '',
				noteId: '',
				isBranch: false,
				isClone: false,
				isMain: true,
				ambulator: false,
				stacionar: false,
				healthResort: false,
				psychiatric: false,
				parentId: null,
				yearStr: '',
				years: []
			},
			error: {},
			objectIndex: 0,
			filterOpen: null,
			filterBy: null,
			opened: null,
			fields: [
				{
					Name: 'Полное наименование МО',
					fieldName: 'name',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию (А-Я)',
					label2: 'По убыванию (Я-А)'
				},
				{
					Name: 'Краткое наименование МО',
					fieldName: 'shortName',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Регион',
					fieldName: 'RegionName',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: 2,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Фактический адрес',
					fieldName: 'address',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Почтовый адрес',
					fieldName: 'postAddress',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Форма собственности',
					fieldName: 'OwnerShipName',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'ИНН',
					fieldName: 'inn',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Адрес официального сайта',
					fieldName: 'webSiteAddress',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Ведомственная принадлежность',
					fieldName: 'DepartmentBelongName',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Номенклатура',
					fieldName: 'NomenclatureName',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Примечание',
					fieldName: 'NoteName',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Включено в перечень',
					fieldName: 'YearStr',
					filterType: 9,
					filterValueType: 1,
					value1: null,
					value2: null,
					values: [],
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Амбулаторные',
					fieldName: 'ambulator',
					filterType: 1,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Стационарные',
					fieldName: 'stacionar',
					filterType: 1,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Санаторно-курортные',
					fieldName: 'healthResort',
					filterType: 1,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Психиатрические',
					fieldName: 'psychiatric',
					filterType: 1,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Баннер',
					filterType: 0,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'QR-код',
					filterType: 0,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				}
			],
			medOrgs: [],
			options: [
				{
					year: '2019'
				},
				{
					year: '2020'
				},
				{
					year: '2021'
				},
				{
					year: '2022'
				}
			],
			children: [],
			upadte: false,
			popupTitle: '',
			params: {
				filters: [
					{
						fieldName: 'isMain',
						filterType: 1,
						filterValueType: 1,
						value1: 'true',
						value2: ''
					}
				],
				sortOption: {
					fieldName: 'RegionName',
					sortType: 2
				},
				pageSize: 5,
				currentPage: 0
			},
			loadToExcell: false,
			departments: [],
			nomenclatures: [],
			notes: [],
			ownership: [],
			moArr: [],
			filterData: {
				sortBy: '',
				searchValue: ''
			},
			noteDisabled: true,
			innClear: true,
			defNomenclatures: [
				'Женская консультация',
				'Молочная кухня',
				'Станция скорой медицинской помощи',
				'Станция переливания крови (центр крови)',
				'Дом ребенка, в том числе специализированный',
				'Физиотерапевтическая поликлиника',
				'Центр медицинской и социальной реабилитации, в том числе с отделением постоянного проживания инвалидов и детей-инвалидов с тяжелыми формами детского церебрального паралича, самостоятельно не передвигающихся и себя не обслуживающих',
				'Психиатрическая больница (стационар) специализированного типа',
				'Психиатрическая больница (стационар) специализированного типа с интенсивным наблюдением',
				'Клинико-диагностическая лаборатория',
				'Бактериологическая больница, в том числе по диагностике туберкулеза',
				'Центр медицинской профилактики',
				'Центр медицины катастроф',
				'Центр медицинских мобилизационных резервов «Резерв»',
				'Медицинский информационно-аналитический центр',
				'Медицинский биофизический центр',
				'Центр военно-врачебной экспертизы',
				'Центр судебно-медицинской экспертизы',
				'Бюро медико-социальной экспертизы',
				'Бюро медицинской статистики',
				'Патологоанатомическое бюро',
				'Бюро судебно-медицинской экспертизы',
				'Центр гигиены и эпидемиологии',
				'Противочумный центр (станция)',
				'Дезинфекционный центр (станция)',
				'Центр гигиенического образования населения',
				'Центр государственного санитарно-эпидемиологического надзора'
			]
		};
	},
	computed: {
		...getters
	},
	methods: {
		...mutations,
		...actionsLocal,
		...methods,
		getSystemYearData: actions.getSystemYearData,
		switchFilterVisibility(key) {
			this.filterData = {
				sortBy: 'asc',
				searchValue: ''
			};
			this.filterOpen = this.filterOpen === key ? null : key;
			this.filterBy = key;
		},
		setFilial(flag) {
			if (flag) {
				this.infoMO.parentId = '';
				this.infoMO.isMain = false;
			} else {
				this.infoMO.parentId = null;
				this.infoMO.isMain = true;
			}
		},
		filial(filial) {
			this.innClear = !filial;
			this.infoMO.isBranch = filial;
			this.infoMO.isClone = !filial;
			this.openClonInn = false;

			if (!filial) {
				let anotherRegionNote = this.notes.find((x) => x.name === 'За пределами субъекта');

				if (anotherRegionNote !== undefined) {
					this.infoMO.noteId = anotherRegionNote.value;
					this.infoMO.years = [];
				}
			}
		},
		setInn(inn) {
			if (inn.length >= 10) {
				this.getMedicalOrgsByINN(inn).then((res) => {
					let mainMO = res.data.find((x) => x.isMain);

					if (mainMO) {
						let found = this.moArr.find((x) => x.value === mainMO.id);
						if (!found) {
							this.moArr.push({ name: mainMO.name, value: mainMO.id });
							this.moArr.sort((a, b) => compareAsc(a.name, b.name));
						}

						let moId = this.infoMO.id;
						Object.assign(this.infoMO, mainMO);
						this.infoMO.id = moId;
						this.infoMO.parentId = mainMO.id;
						this.infoMO.isMain = false;

						this.openClonInn = true;
					} else if (res.data.length > 0) {
						this.infoMO.inn = '';
					} else {
						this.infoMO.isMain = true;
						this.infoMO.isBranch = false;
						this.infoMO.isClone = false;
						this.innClear = false;
					}
				});
			} else {
				this.innClear = true;
			}
		},
		setName(id, type) {
			let name = '';
			this[type].forEach((item) => {
				if (item.value === id) name = item.name;
			});
			return name;
		},
		openConsole(text) {
			console.log(text);
		},
		notesChange(id) {
			this.noteDisabled = false;
			this.nomenclatures.forEach((item) => {
				if (item.value === id) {
					this.defNomenclatures.forEach((name) => {
						if (name === item.name) {
							this.infoMO.noteId = '0f366d7c-e474-4ae3-a2b9-05164be8ef1f';
							this.noteDisabled = true;
						}
					});
				}
			});
		},
		openPopUp() {
			this.infoMO.regionId = this.getAuthUser.regionId;
			this.resetFormErrors();
			this.update = false;
			this.popupTitle = 'Добавить МО';
			this.open = true;
		},
		editMO(item, index) {
			Object.assign(this.infoMO, item);
			if (item.inn) {
				this.innClear = false;
			} else {
				this.innClear = true;
			}
			this.noteDisabled = false;
			this.notesChange(item.nomenclatureId);
			this.objectIndex = index;
			this.infoMO = item;
			this.resetFormErrors();
			this.popupTitle = 'Редактировать МО';
			this.open = true;
			this.update = true;
		},
		deliteUser(id) {
			/* eslint-disable */
			let yea = confirm('Вы уверены что хотите удалить?');
			if (yea) {
				this.delUser(id);
			}
		},
		sendEmail(login) {
			let yea = confirm('Отправить подтверждение на E-mail?');
			if (yea) {
				this.sendEmailConfirmedCode(login);
			}
			/* eslint-enable */
		},
		closePopup() {
			this.open = false;
			this.innClear = true;
			this.infoMO = {
				name: '',
				regionId: this.getAuthUser.regionId,
				shortName: '',
				address: '',
				postAddress: '',
				ownerShipId: '',
				inn: '',
				isBranch: false,
				isClone: false,
				isMain: true,
				webSiteAddress: '',
				departmentBelongId: null,
				nomenclatureId: '',
				noteId: '',
				ambulator: false,
				stacionar: false,
				healthResort: false,
				psychiatric: false,
				parentId: null,
				deletedBy: '',
				yearStr: '',
				years: []
			};
		},
		openRow(item) {
			if (item.hasBranch) {
				if (this.opened === item.id) {
					this.opened = null;
				} else {
					this.opened = item.id;
				}
			}
		},
		yearsAreAvailable(noteId) {
			return noteId === '150a6a2c-e499-435d-9c2c-8488e60f5555' || noteId === '789200c6-8ddd-4dcd-abe5-3e3ab5e9ad7f';
		},
		resetFormErrors() {
			this.error = {
				name: null,
				regionId: null,
				shortName: null,
				address: null,
				postAddress: null,
				ownerShipId: null,
				inn: null,
				webSiteAddress: null,
				nomenclatureId: null,
				noteId: null,
				parentId: null,
				years: null
			};
		},
		getFormErrors() {
			this.resetFormErrors();
			if (!this.infoMO.name) {
				this.error.name = 'Введите полное наименование';
			}
			if (!this.infoMO.shortName) {
				this.error.shortName = 'Введите краткое наименование';
			}
			if (!this.infoMO.regionId) {
				this.error.regionId = 'Выберите регион';
			}
			if (!this.infoMO.address) {
				this.error.address = 'Введите фактический адрес';
			}
			if (!this.infoMO.postAddress) {
				this.error.postAddress = 'Введите почтовый адрес';
			}
			if (!this.infoMO.ownerShipId) {
				this.error.ownerShipId = 'Выберите форму собственности';
			}
			if (!this.infoMO.inn) {
				this.error.inn = 'Введите ИНН';
			}
			if (!this.infoMO.webSiteAddress) {
				this.error.webSiteAddress = 'Введите адрес официального сайта';
			}
			if (!this.infoMO.nomenclatureId) {
				this.error.nomenclatureId = 'Выберите номенклатуру';
			}
			if (!this.infoMO.noteId) {
				this.error.noteId = 'Выберите примечание';
			}
			if (this.infoMO.isBranch && !this.infoMO.parentId) {
				this.error.parentId = 'Выберите головную организацию';
			}
			if (this.yearsAreAvailable(this.infoMO.noteId) && this.infoMO.years.length === 0) {
				this.error.years = 'Выберите годы';
			}
			let errors = Object.values(this.error).some((item) => {
				return item !== null;
			});
			return errors;
		},
		sendForm() {
			if (this.getFormErrors()) {
				return;
			}

			if (!this.update) {
				this.addMO(this.infoMO).then((res) => {
					let addedMO = {};
					Object.assign(addedMO, this.infoMO);

					addedMO.id = res.data.id;
					this.infoMO.id = res.data.id;
					if (this.infoMO.isMain) {
						this.medOrgs.unshift(addedMO);
					} else {
						let mainMO = this.medOrgs.find((x) => x.id === this.infoMO.parentId);

						if (mainMO) {
							mainMO.hasBranch = true;

							if (this.opened !== mainMO.id) {
								this.openRow(mainMO);
							}

							mainMO.branches.unshift(addedMO);
						}
					}

					this.closePopup();
				});
			} else {
				this.updateMO(this.infoMO).then((res) => {
					if (res.errors) {
						console.log(res.errors);
					} else {
						if (this.infoMO.isMain) {
							let updatedMO = this.medOrgs[this.objectIndex];
							Object.assign(updatedMO, this.infoMO);
							updatedMO.branches = [];

							if (this.opened === updatedMO.id) {
								this.openRow(updatedMO);
								this.openRow(updatedMO);
							}
						} else {
							let mainMO = this.medOrgs.find((x) => x.id === this.infoMO.parentId);

							if (mainMO) {
								let updatedMO = mainMO.branches.find((x) => x.id === this.infoMO.id);

								if (updatedMO) {
									Object.assign(updatedMO, this.infoMO);
								}
							}
						}

						this.closePopup();
					}
				});
			}
		},
		updateRow(rowsVisible) {
			this.rowsVisible = rowsVisible;
			this.params.pageSize = rowsVisible;
			this.getData(this.params, true);
		},
		openBunnersPopUp(item) {
			if (item.oldId) {
				this.bunnersId = item.oldId;
			} else {
				this.bunnersId = item.id;
			}
			this.openBunners = true;
		},
		closeBunners() {
			this.bunnersId = '';
			this.openBunners = false;
		},
		getData(params, flag) {
			this.loadingTable = true;
			// если фильтруем/сортируем то возвращаем пагинацию на 1 страницу
			if (flag) {
				this.params.currentPage = 0;
				this.page = 0;
			}

			this.getMedicalOrgs(params).then((res) => {
				this.medOrgs = res.data.data;
				this.totalCount = Math.ceil(res.data.totalRecords / this.rowsVisible);
				this.loadingTable = false;
			});
		},
		navigation(name, page) {
			this.page = page;
			this[name].currentPage = page;

			this.getData(this[name], false);
		},
		downloadExcell(params) {
			this.loadToExcell = true;

			this.$http
				.post('MedicalOrgs/GetMedicalOrgsExcel', params, {
					responseType: 'blob',
					headers: {
						'Access-Control-Expose-Headers': 'Content-Disposition'
					}
				})
				.then((res) => {
					const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', 'НОК_Медицинские организации.xlsx');
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
					this.loadToExcell = false;
				});
		},
		downloadQr(item) {
			let type = 'ambulator';
			if (item.ambulator) {
				type = 'ambulator';
			} else if (item.stacionar) {
				type = 'stacionar';
			} else if (item.healthResort) {
				type = 'healthResort';
			} else {
				type = 'psychiatric';
			}
			this.getQr(item.id, type).then((res) => {
				let fileNameAndType = decodeURI('QR.png');

				const url = window.URL.createObjectURL(new Blob([res.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', fileNameAndType);
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			});
		}
	},
	mounted() {
		this.getData(this.params, true);
		this.resetFormErrors();

		this.getRegions().then((res) => {
			this.regions = [];
			res.data.forEach((item) => {
				this.regions.push({ name: item.Name, value: item.Id });
			});
		});
		this.getDepartmentBelong().then((res) => {
			this.departments = [];
			res.data.forEach((item) => {
				this.departments.push({ name: item.name, value: item.Id });
			});
		});
		this.getMedicalOrgsByIdRegion().then((res) => {
			this.moArr = [];
			res.data.forEach((item) => {
				this.moArr.push({ name: item.moname, value: item.id });
			});
		});
		this.getNomenclatures().then((res) => {
			this.nomenclatures = [];
			res.data.forEach((item) => {
				this.nomenclatures.push({ name: item.name, value: item.Id });
			});
		});
		this.getNotes().then((res) => {
			this.notes = [];
			res.data.forEach((item) => {
				this.notes.push({ name: item.name, value: item.Id });
			});
		});
		this.getOwnership().then((res) => {
			this.ownership = [];
			res.data.forEach((item) => {
				this.ownership.push({ name: item.name, value: item.Id });
			});
		});

		this.getSystemYearData().then((result) => {
			let yearField = this.fields.find((x) => x.fieldName === 'YearStr');

			if (yearField !== undefined) {
				yearField.filterOptions = result.data.availableYears.map((x) => {
					return { name: x.toString(), checked: false };
				});
			}
		});
	}
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.default-filial-clone {
	width: 90px;
	height: 24px;
	border-radius: 4px;
	display: flex;
	background: #a8b1ce;
	color: #fff;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 0px;
	gap: 10px;
}
.default-filial-clone-2 {
	width: 130px;
	height: 24px;
	border-radius: 4px;
	display: flex;
	background: #a8b1ce;
	color: #fff;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 0px;
	gap: 10px;
}

.preloader {
	z-index: 10;
	background: rgba(0, 0, 0, 0.09);
}

.row-w {
	min-width: 200px;
	max-width: 200px;
	width: 200px;
}

.row-w-2 {
	min-width: 350px;
	max-width: 350px;
	width: 350px;
}

.sticky-table__header {
	left: 0;
	z-index: 9;
	position: sticky;
	min-width: 500px;
	max-width: 500px;
	width: 500px;
	background-color: #fff;
}
.sticky-table__row {
	min-width: 500px;
	max-width: 500px;
	width: 500px;
	left: 0;
	position: sticky;
	background: #fff;
}
.sticky-table__row-2 {
	min-width: 500px;
	max-width: 500px;
	width: 500px;
	left: 0;
	position: sticky;
	background: #eeeff6;
}
.preloader {
	z-index: 10;
	background: rgba(0, 0, 0, 0.09);
}
.custom-w-table-144 {
	max-width: calc(100vw - 144px - 3rem);
}

.custom-w-table-360 {
	max-width: calc(100vw - 360px - 3rem);
}
.td-name {
	position: absolute;
	background: #fff;
	width: 30%;
	left: 0;
	margin-top: -1px;
	z-index: 10;
}

.table-mo {
	padding-left: 30%;
}

.table-mo:after {
	content: '';
	position: absolute;
	left: 0;
	height: calc(100% - 112px);
	top: 0;
	width: 30%;
	z-index: 8;
	background: #fff;
}

.select-label {
	font-weight: 700;
	font-size: 14px;
	display: block;
	line-height: 18px;
	margin-bottom: 12px;
	color: #a8b1ce;
}

.radio-group {
	margin-bottom: 20px;
}

.multiselect__tags {
	font-weight: 400;
	font-size: 14px;
	line-height: 50px;
	min-height: 50px;
	width: 100%;
	border-radius: 10px;
	border: 1px solid #a8b1ce;
	padding: 0 20px;
	color: #6a6e83;
}
.multiselect__tags-wrap {
	display: flex;
	align-items: center;
	height: 50px;
}
.multiselect__option--highlight:after {
	display: none;
}
.multiselect__select {
	height: 50px;
}
.label {
	align-items: center;
	cursor: pointer;
}
.label:hover .label-text {
	text-decoration: none;
}
.label-text {
	text-align: center;
	font-weight: 700;
	font-size: 14px;
	line-height: 18px;
	text-decoration-line: underline;
	color: #3377ff;
}
.ava {
	background: #f2f5ff;
	border-radius: 50%;
	width: 80px;
	overflow: hidden;
	margin-right: 20px;
	height: 80px;
	display: flex;
	background-size: cover;
	background-position: 50% 50%;
}
.ava .icon,
.ava img {
	margin: auto;
}

.t-overflow {
	overflow: auto;
}

li.arrow::before {
	@apply block w-2 h-2 mt-1.5 mr-2 border-b-2 border-l-2 border-gray-400;
	content: '';
	transform: rotate(45deg);
}
.arrow:last-child::before {
	transform: rotate(-135deg);
}

.input-box {
	position: relative;
	margin-bottom: 10px;
}

.input-box label {
	font-weight: 700;
	font-size: 14px;
	display: block;
	line-height: 18px;
	margin-bottom: 12px;
	color: #a8b1ce;
}

.t-overflow::-webkit-scrollbar {
	width: 11px; /* ширина всей полосы прокрутки */
}

.t-overflow::-webkit-scrollbar-track {
	background: rgba(168, 177, 206, 0.3); /* цвет зоны отслеживания */
}

.t-overflow::-webkit-scrollbar-thumb {
	background: #3377ff;
	border-radius: 10px;
}

.error-text {
	color: #e94b3d;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
}

.error .vs__dropdown-toggle,
.error .multiselect__tags {
	border: 1px solid #e94b3d;
}
</style>

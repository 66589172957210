// Если нам надо оплучить глобальные данные
// import { getters } from '@/store/store';
// Если нам надо обработать данные используем мутации
// import { mutations } from './mutations';
import { compareAsc } from '@/store/store';

export default {
	getMedicalOrgs(params) {
		return new Promise((resolve, reject) => {
			this.$http
				.post('MedicalOrgs/GetMedicalOrgs', params)
				.then((res) => {
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	getMedicalOrgsByIdRegion() {
		return new Promise((resolve, reject) => {
			this.$http
				.post('/MedicalOrgs/GetMainMedOrgs')
				.then((result) => {
					result.data.sort((a, b) => compareAsc(a.Name, b.Name));
					resolve(result);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	addMO(user) {
		return new Promise((resolve, reject) => {
			this.$http
				.post('MedicalOrgs/AddMedicalOrg', user)
				.then((res) => {
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	updateMO(user) {
		return new Promise((resolve, reject) => {
			this.$http
				.put('MedicalOrgs/UpdateMedicalOrg', user)
				.then((res) => {
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	getRegions() {
		return new Promise((resolve, reject) => {
			this.$http
				.get('MedicalOrgs/Regions')
				.then((result) => {
					result.data.sort((a, b) => compareAsc(a.Name, b.Name));
					resolve(result);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	getMedicalOrgsByINN(inn) {
		return new Promise((resolve, reject) => {
			this.$http
				.get(`MedicalOrgs/GetMedicalOrgsByINN?inn=${inn}`)
				.then((res) => {
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	getDepartmentBelong() {
		return new Promise((resolve, reject) => {
			this.$http
				.get('MedicalOrgs/DepartmentBelong')
				.then((result) => {
					result.data.sort((a, b) => compareAsc(a.name, b.name));
					resolve(result);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	getNomenclatures() {
		return new Promise((resolve, reject) => {
			this.$http
				.get('MedicalOrgs/Nomenclatures')
				.then((result) => {
					result.data.sort((a, b) => compareAsc(a.name, b.name));
					resolve(result);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	getNotes() {
		return new Promise((resolve, reject) => {
			this.$http
				.get('MedicalOrgs/Notes')
				.then((res) => {
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	getOwnership() {
		return new Promise((resolve, reject) => {
			this.$http
				.get('MedicalOrgs/Ownership')
				.then((result) => {
					result.data.sort((a, b) => compareAsc(a.name, b.name));
					resolve(result);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	getQr(id, type) {
		return new Promise((resolve, reject) => {
			this.$http
				.get(`Anketa/GetQrCode?moId=${id}&anketaType=${type}`, {
					responseType: 'blob',
					headers: {
						'Access-Control-Expose-Headers': 'Content-Disposition'
					}
				})
				.then((res) => {
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	}
};
